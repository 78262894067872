import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ContentPage from '../components/contentPage';

class IndexPage extends React.Component {
  render() {
    const { data } = this.props;
    const { seo, content } = data.imprintEnJson;

    return (
      <Layout data={this.props.data} location={this.props.location}>

        <SEO {...seo} />

        <ContentPage
          preHeader={'Impect'}
          header={content.childMarkdownRemark.frontmatter.title}
          headerImage={data.file.childImageSharp.fixed}
          contentHTML={content.childMarkdownRemark.html}
        />
      </Layout>
    );
  }
}


export default IndexPage;

export const pageQuery = graphql`
    query pageImprintEnQuery {
        site {
            siteMetadata {
                languages {
                    defaultLangKey
                    langs
                }
            }
        }
        file(relativePath: { eq: "header-bg-medis.png" }) {
            childImageSharp {
                fixed(width: 2500 height: 793 quality: 100) {
                    ...GatsbyImageSharpFixed_withWebp
                }
            }
        }
        imprintEnJson {
            seo {
                title
                description
                lang
                keywords
            }
            content {
                childMarkdownRemark {
                    frontmatter {
                        title
                    }
                    html
                    rawMarkdownBody
                }
            }
        }
    }
`;
